/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {



  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var gallerySwiper = new Swiper ('.gallery-container', {
            // Optional parameters
            direction: 'horizontal',
            slidesPerView: 3,
            slidesPerColumn: 2,
            spaceBetween: 30,
            slidesOffsetAfter: 30,
            loop: false,
            pagination: false,
            scrollbar: false,

            // Navigation arrows
            navigation: {
                nextEl: jQuery('.swiper-button-next'),
                prevEl: jQuery('.swiper-button-prev')
            },

            // Responsive breakpoints
            breakpoints: {
                // when window width is <= 480px
                480: {
                    slidesPerView: 1,
                    slidesPerColumn: 1,
                    spaceBetween: 0
                },
                // when window width is <= 640px
                768: {
                    slidesPerView: 2,
                    slidesPerColumn: 1,
                    spaceBetween: 30
                }
            }
        });

        $('.gallery-container').on('click', 'a', function (e) {
            e.preventDefault();

            var pswpElement = document.querySelectorAll('.pswp')[0],
                sliderElement = $('.swiper-slide'),
                items = [],
                options = {};

            for( var i = 0; i < sliderElement.length; i ++) {
                var element = $(sliderElement[i]).find('a');

                items.push({
                    src: element.attr('href'),
                    w: element.attr('data-size-width'),
                    h: element.attr('data-size-height')
                });
            }

            options.index = $(this).attr('data-index');

            // Initializes and opens PhotoSwipe
            var gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
            gallery.init();
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // Slow scroll from subnavigation link
  $('.sub-nav-link').on('click', function(event) {
    event.preventDefault();

    var id  = $(this).attr('href'),
      top = $(id).offset().top;

    $('body,html').animate({scrollTop: (top)}, 500);
  });

  //Animation Function
  function animateFnc( elem, animateClass) {
    elem.each(function () {
      var elemPos = $(this).offset().top,
          topOfWindow = $(window).scrollTop(),
          windowHeight = $(window).innerHeight(),
          windowWidth = $(window).innerWidth(),
          bootomOffset = 75;

      if (windowWidth < 768) {
        bootomOffset = 50;
      } else if (windowWidth < 992) {
        bootomOffset = 65;
      }

      if (elemPos < topOfWindow - bootomOffset + windowHeight) {
        $(this).addClass( animateClass + ' animated');
      }
    });
  }

  animateFnc($(' .section'), 'fadeIn');
  animateFnc($(' .posts-container'), 'fadeIn');

  $(window).scroll(function () {
    animateFnc($('.section'), 'fadeIn');
  });

  //Header
    var $fixed_header = $("header.banner"),
        $window = $(window);

    $window.scroll(function () {
        if ($(this).scrollTop() > 100) {
            $fixed_header.addClass("fixed_top_hide");
        } else if ($(this).scrollTop() <= 100 && $fixed_header.hasClass("fixed_top_hide")) {
            $fixed_header.removeClass("fixed_top_hide");
        }
    });

    var lastScrollTop = 0;
    $window.scroll(function(){
        var st = $(this).scrollTop();
        if (st > (lastScrollTop)){
            $('.fixed_top_hide').removeClass("fixed_top_show");
            $('.subnavigation.fixed').removeClass('fixed-up');
        } else {
            $('.fixed_top_hide').addClass("fixed_top_show");
            $('.subnavigation.fixed').addClass('fixed-up');
        }
        lastScrollTop = st;
    });

    //Header Slider
    var bulletMas = [];

    $('.bullet-title').each(function () {
        bulletMas.push( $(this).text() );
    });

    var SliderSwiper = new Swiper('.slider-container', {
        // Optional parameters
        direction: 'horizontal',
        slidesPerView: 1,
        slidesPerColumn: 1,
        spaceBetween: 0,
        slidesOffsetAfter: 0,
        loop: false,
        // scrollbar: true,
        speed: 700,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            bulletElement: 'span',
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + '">' + (bulletMas[index]) + '</span>';
            }
        },
        autoplay: {
            delay: 4000
        }
    });

  //Subnavigation
  var subnavElWrap = $('.section.subnavigation'),
      subnavEl = $('section.subnavigation');

  function subnavPosition() {

    var subnavElPositionTop = subnavElWrap.offset().top,
        topOfWindow = $(window).scrollTop(),
        subnavHeight = subnavElWrap.children().innerHeight();

    if (subnavElPositionTop < topOfWindow + 20) {
        subnavElWrap.children().addClass('full-width');
        subnavElWrap.children().css("height", subnavHeight);
        subnavEl.addClass('fixed');
    } else {
        subnavElWrap.children().removeClass('full-width');
        subnavEl.removeClass('fixed').removeClass('fixed-up');
    }
  }
  if( subnavElWrap.is('div')) {

    $(window).scroll(function () {
      subnavPosition();
    });
  }

  //remove empty li from Subnav added wp
  $('.subnav-list li').each(function () {
    if(this.innerHTML === '') {
        this.remove();
    }
  });

  //section Latest news in Home page
  var postThumbnailHome = $('.post_thumbnail'),
    post_thumbnailHomeWidth = postThumbnailHome.width();

  postThumbnailHome.height(post_thumbnailHomeWidth);

  maintainSameHeight($('.latest_post_title'));

  function maintainSameHeight($list) {
    var height = 0;

    $list.each(function () {
        var $this = $(this);
        if ($this.innerHeight() > height) {
            height = $this.innerHeight();
        }
    });
    $list.css("height", height);
  }

    // Footer form validation
    var emailInput = $('#subscribe-form input[type="email"]');
    emailInput.on('focus', function () {
        $('.message').removeClass('show-message');
        $(this).removeClass('error-border');
    });

    emailInput.on('blur', function () {
        var email = $(this).val();

        if (email.length > 0 && (email.match(/^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/i) || []).length !== 1) {
            $(this).addClass('error-border');
            $('.message').addClass('show-message');
        }
    });

    $('#subscribe-form').on('submit', function (event) {
        if(emailInput.val() === '') {
            event.preventDefault();
            emailInput.addClass('error-border');
            $('.message').addClass('show-message');
        }
    });

    //next gen gallery swiper thumbnail
    var GallerySwiper = new Swiper('.thumbnail-gallerys-wrap', {
        // Optional parameters
        direction: 'horizontal',
        slidesPerView: 3,
        slidesPerColumn: 2,
        spaceBetween: 30,
        slidesOffsetAfter: 30,
        loop: false,
        pagination: false,
        scrollbar: false,

        breakpoints: {
            // when window width is <= 480px
            480: {
                slidesPerView: 1,
                slidesPerColumn: 1,
                spaceBetween: 0
            },
            // when window width is <= 640px
            768: {
                slidesPerView: 2,
                slidesPerColumn: 1,
                spaceBetween: 30
            }
        }
    });

})(jQuery); // Fully reference jQuery after this point.
